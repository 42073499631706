body {
  margin: 0;
  background: #f7f7fa;
}

* {
  box-sizing: border-box;
}

.app {
  min-height: calc(100vh - 40px);
  padding-top: 56px;
}

.app-wrapper {
  padding: 20px;
  padding-top: 40px;
  min-height: 0;
  @media only screen and (max-width: 480px) {
    padding: 16px;
  }
}

.fade-in {
  animation: fadeIn 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) backwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
