.input-box input:not([type='range']),
.input-box textarea {
  border-radius: 0;
  border-width: 0 0 1px 0;
  text-align: center;
}
.btn {
  font-size: 16px;
  height: 44px;
}
.app-container {
  min-height: calc(100vh - 40px);
  padding-top: 56px;
}
